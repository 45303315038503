<template>
	<div v-if="show">
		<div class="item_line" style="
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      ">
			<span class="titlename" style="
          font-size: 18px;
          color: #333;
          font-weight: bold;
          text-align: left;
        ">{{ detail.name }}</span>
			<p class="titlename" style="
          font-size: 18px;
          color: #333;
          font-weight: bold;
          text-align: left;
        ">
				坐席统计
			</p>
		</div>
		<div class="item_line">
			<span class="titlename">{{ detail.wymeetingtype_name }}</span>
			<!-- <span class="titlename">{{ detail.dqtime }}</span> -->
		</div>
		<div style="display: flex; align-items: center; justify-content: flex-start">
			<div style="display: flex; align-items: center; justify-content: center;flex-direction: column;">
				<div style="background-color: rgb(232, 45, 49); width: 25px; height: 25px"></div>
				<div style="margin-left: 5px">缺席</div>
			</div>
			<div style="display: flex; align-items: center; justify-content: center;flex-direction: column;margin:0 15px;">
				<div style="background-color: rgb(235,241,255); width: 25px; height: 25px"></div>
				<div style="margin-left: 5px">未签到</div>
			</div>
			<div style="display: flex; align-items: center; justify-content: center;flex-direction: column;">
				<div style="background-color: rgb(83,194,30); width: 25px; height: 25px"></div>
				<div style="margin-left: 5px">已签到</div>
			</div>
		</div>
		<div style="width: 100%; margin-left: 10px">
			<zuo27 ref="zuo27"></zuo27>
		</div>
	</div>
</template>

<script>
	import zuo27 from '@/components/zuoweitu/zuo27.vue'
	export default {
		components:{ zuo27 },
		props: {
			istz: {
				type: Boolean,
				default: false,
			},
			updataQx: {
				type: Number,
				default: 1,
			},
		},
		data() {
			return {
				show:false,
				detail: "",
				id:''
			};
		},
		methods: {			
			setmap() {
				this.$post({
					url: '/api/wyroomzc/hyzc',
					params: {
						wymeeting_id:this.id
					}
				}).then((res) => {	
					console.log(res)
					this.detail = res.wymeeting
					this.$refs.zuo27.metting_id = this.id
					if(res.zuoci.pai.length==0){
						this.$refs.zuo27.init()
						this.$refs.zuo27.dialogVisible = true
					}else{
						let list = []
						res.zuoci.pai.forEach((item,index)=>{
							list[index] = []
							item.lie.forEach((item2,index2)=>{
								item2.name = item2.user?item2.user.name:''
								list[index].push(item2)
							})
						})						
						
						let zxlist=[]
						res.zuoci.zhu.forEach((item,index)=>{
							item.name = item.user?item.user.name:''
							zxlist.push(item)
						})
						
						this.$refs.zuo27.list = list
						this.$refs.zuo27.zxset = zxlist
						this.$refs.zuo27.dialogVisible = true
					}
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.left_info {
		padding: 15px;
		margin-top: 25px;
		box-sizing: border-box;
	}

	.zxt {
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		padding-left: 40px;
	}

	.zuowei_pai {
		display: flex;
		margin-bottom: 20px;
		width: 100%;
		align-items: center;
	}

	.zuowei_lie {
		padding: 5px 10px;
		margin-right: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
	}

	.zuowei_lie:nth-last-child(1) {
		margin-right: 0;
	}

	.zxt_im {
		background-color: rgb(180, 180, 180);
		width: 80%;
		padding: 3px 0;
		text-align: center;
		font-size: 16px;
	}

	.zxt_item {
		padding: 5px 10px;
		margin-right: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
	}

	.zxt_item:nth-last-child(1) {
		margin-right: 0;
	}

	.desb {
		background-color: rgb(180, 180, 180);
	}

	.desb {
		background-color: rgb(180, 180, 180);
	}

	.redbox {
		background-color: rgb(232, 45, 49);
		color: #fff !important;
	}

	.overhide {
		width: 50px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}

	.tapmenu {
		font-size: 16px;
		margin-right: 16px;
		cursor: pointer;
		padding-bottom: 5px;
	}

	.active_tap {
		color: #ec4a4a;
		border-bottom: 2px solid #ec4a4a;
	}

	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.titlename {
			margin-left: 10px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		height: 85vh;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		padding: 10px 0;
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-form-item__error {
		padding-top: 0;
	}

	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: "" !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .myinputicon {
		cursor: pointer;
	}

	::v-deep .myinputicon:hover {
		color: red !important;
	}
</style>