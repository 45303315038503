var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dialogVisible)?_c('div',{staticClass:"left_info",staticStyle:{"width":"1350px","margin":"auto"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","padding-left":"40px"}},_vm._l((_vm.zxset),function(item,index){return _c('div',{staticStyle:{"display":"flex","align-items":"center","flex-direction":"column"}},[_c('div',{staticClass:"inset zxt_zw",style:({ backgroundColor: _vm.setcolor(item.user)}),attrs:{"id":'zx'+index}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.setnumber(index,_vm.zxset.length))+" ")])])}),0),_c('div',{staticClass:"zxt_im"},[_vm._v("主席台")]),_c('div',{staticStyle:{"margin-top":"20px","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","position":"relative"}},[_vm._l((_vm.list),function(item,index){return _c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"10px"}},[_vm._l((item),function(item2,index2){return (index==0)?_c('div',{staticClass:"innerbox",staticStyle:{"display":"flex","align-items":"center","flex-direction":"column"}},[(index==0)?_c('div',{staticClass:"inset",style:({
						marginRight:index2==3?'40px':index2==11?'40px':'0',
						borderRight:index2==3?'1px solid #333':index2==11?'1px solid #333':'',
						marginLeft:index2==0?'50px':'',
						backgroundColor: _vm.setcolor(item2.user)
					}),attrs:{"id":index+','+index2}},[_vm._v(" "+_vm._s(item2.name)+" ")]):_vm._e(),_c('div',{style:({marginRight:index2==3?'40px':index2==11?'40px':'0',marginLeft:index2==0?'50px':''})},[_vm._v(" "+_vm._s((index+1)+'-'+(index2+1))+" ")])]):_vm._e()}),_vm._l((item),function(item2,index2){return (index!=0)?_c('div',{staticClass:"innerbox",staticStyle:{"display":"flex","align-items":"center","flex-direction":"column"}},[_c('div',{staticClass:"inset",style:({
						marginRight:index2==5?'40px':index2==13?'40px':'0',
						borderRight:index2==5?'1px solid #333':index2==13?'1px solid #333':'',
						backgroundColor: _vm.setcolor(item2.user)
					}),attrs:{"id":index+','+index2}},[_vm._v(" "+_vm._s(item2.name)+" ")]),_c('div',{style:({marginRight:index2==5?'40px':index2==13?'40px':'0'})},[_vm._v(" "+_vm._s((index+1)+'-'+(index2+1))+" ")])]):_vm._e()})],2)}),_c('div',{staticStyle:{"position":"absolute","left":"-8%"}},_vm._l((7),function(item){return _c('div',{staticClass:"inset",staticStyle:{"display":"flex","align-items":"center","margin-bottom":"30px","border":"none"}},[_vm._v(" 第"+_vm._s(item)+"排 ")])}),0)],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }